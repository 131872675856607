export default [
  // Creator Routes
  {
    path: "/creators/creators/:page?",
    name: "Creators",
    component: () =>
      import(
        /* webpackChunkName: "service" */ "../views/creator/creator/list/Creators.vue"
      ),
    meta: {
      title: "크리에이터 관리",
      requiresAuth: true,
      requiredRoles: ["ADMIN", "OCS_OPERATOR"],
    },
  },
  {
    path: "/creators/creators/single/:creatorId",
    name: "SingleCreator",
    component: () =>
      import(
        /* webpackChunkName: "service" */ "../views/creator/creator/single/SingleCreator.vue"
      ),
    meta: {
      navTree: ["Creators"],
      title: "크리에이터 상세",
      requiresAuth: true,
      requiredRoles: ["ADMIN", "OCS_OPERATOR"],
    },
  },
  {
    path: "/creators/add",
    name: "CreatorAdd",
    component: () =>
      import(
        /* webpackChunkName: "service" */ "../views/creator/creator/add/CreatorAdd.vue"
      ),
    meta: {
      title: "크리에이터 생성",
      requiresAuth: true,
      requiredRoles: ["ADMIN", "OCS_OPERATOR"],
    },
  },
];
