<template>
  <div 
    :class="[
      'layout-app',
      {
        'layout-app-compact': compact
      }
    ]"
  >
    <Sidebar :menu="menu">
      <template v-slot:head>
        <div class="sidebar-logo">
          <div class="title">OGQ Creator Studio</div>
          <div class="subtitle">ADMIN</div>
        </div>
      </template>
    </Sidebar>
    <div class="content" ref="wrapper">
      <div class="content-container">
        <div class="content-head">
          <NavPath
            class="nav-path"
            v-if="$route.meta.navTree && !isNavPathOnRight"
            :tree="$route.meta.navTree"
          />
          <div class="inner">
            <div class="content-title">
              <slot name="title" v-if="$slots.title"> </slot>
              <admin-title v-else>
                {{ title }}
              </admin-title>
              <span v-if="$slots.subtitle" class="subtitle">
                <slot name="subtitle"> </slot>
              </span>
            </div>
            <slot name="head">
              <NavPath
                class="nav-path"
                v-if="$route.meta.navTree && isNavPathOnRight"
                :tree="$route.meta.navTree"
              />
            </slot>
          </div>
        </div>
        <div class="content-body">

          <div 
            v-if="$slots.bodyHead"
            :class="[
              'content-body-head',
              {
                'content-sticky': shouldFilterSticky
              }
            ]"
            ref="bodyHead"
          >
            <div class="inner-wrap" :style="stickyStyle">
              <div class="inner">
                <slot name="bodyHead"> </slot>
              </div>
            </div>
          </div>


          <slot name="body"> </slot>
        </div>
        <div class="content-footer">
          <slot name="footer"> </slot>
        </div>
      </div>
    </div>

    <Toasts />
  </div>
</template>

<script>
import {Sidebar, NavPath} from "@ogqcorp/v-ui"
import sidebarMenu from "@/components/sidebar/SidebarTree"
import AdminTitle from "./AdminTitle";
import Toasts from '@/components/toast/Toasts'

export default {
  props: [
    "title", "isNavPathOnRight", "stickyFilter", "compact"
  ],
  data() {
    return {
      menu: sidebarMenu,
      shouldFilterSticky: false,
      stickyOffsetTop: 0
    }
  },
  mounted() {
    if (this.stickyFilter) {
      this.attachStickyListeners()
    }
  },
  beforeUnload() {
    this.attachStickyListeners(true)
  },
  methods: {
    attachStickyListeners(detach=false) {
      // console.log('attachStickyLintenser', detach, `${detach ? 'remove' : 'add'}EventListener`)
      this.$refs.wrapper[`${detach ? 'remove' : 'add'}EventListener`]('scroll', this.stickyFilterController, false) 
    },
    stickyFilterController() {
      // console.log('scroll')
      const rect = this.$refs.bodyHead.getBoundingClientRect()
      // console.log('log', rect)
      if (!this.shouldFilterSticky && rect.top <= 0) {
        this.shouldFilterSticky = true
      } else if (this.shouldFilterSticky && rect.top > 0) {
        this.shouldFilterSticky = false
      }
      this.stickyOffsetTop = this.$refs.wrapper.scrollTop
    }
  },
  computed: {
    stickyStyle() {
      return this.stickyFilter && this.shouldFilterSticky ? {
        top: this.stickyOffsetTop + 'px'
      } : ''
    }
  },
  components: {
    Sidebar,
    NavPath,
    Toasts,
    "admin-title": AdminTitle,
  },
};
</script>

<style lang="scss" scoped>
.layout-app {
  --content-container-width: 1080px;
  display: flex;
  width: 100%;
  height: 100%;
  &.layout-app-compact {
    --content-container-width: 960px;
  }
}
.content {
  position: relative;
  flex: 1;
  background-color: var(--mono-050);
  padding-top: 56px;
  padding-bottom: 56px;
  overflow: auto;
  padding: 56px 16px;
  z-index: 3;
}
.content-container {
  width: var(--content-container-width);
  margin: 0 auto;
  // position: relative;
}
.content-head .inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 50px;
}

.content-title {
  display: flex;
  align-items: center;
}

.content-title .subtitle {
  margin-left: 10px;
  font-size: 12px;
  color: var(--mono-500);
}

.nav-path {
  margin: 0 0 10px;
}

.content-body-head {
  margin-bottom: 38px;
  border-bottom: 1px solid var(--mono-300);
  &.content-sticky {
    margin-bottom: 0;
    border: 0;
  }
  &.content-sticky .inner-wrap {
    position: absolute;
    left: 0;
    width: 100%;
    // left: 50%;
    // width: 100%;
    // transform: translate3d(-50%, 0, 0);
    background-color: var(--mono-050);
    box-shadow: 0px 5px 30px rgba(27, 34, 63, 0.05);
    padding: 20px;
    z-index: 2;
    border-bottom: 1px solid var(--mono-300);
    .inner {
      max-width: var(--content-container-width);
      margin: 0 auto;
    }
    .inner:deep > div {
      padding: 0;
    }
  }
}
.content-body {
}
.content-footer {
  margin-top: 40px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.content-footer ::v-slotted(button) {
  margin: 0 0 0 10px;
}

.sidebar-logo {
  font-size: 0.9375rem;
  text-align: center;
  font-weight: bold;
}

.sidebar-logo .title {
  color: #212529;
}
.sidebar-logo .subtitle {
  color: #868e96;
}

</style>
