export default [
  // Dictionary Routes
  // {
  //   path: "/dictionaries/dictionary/list/:page?",
  //   name: "Dictionary",
  //   component: () =>
  //     import(
  //       /* webpackChunkName: "service" */ "../views/dictionary/dictionary/list/Dictionary.vue"
  //     ),
  //   meta: {
  //     title: "사전 관리",
  //     requiresAuth: true,
  //     requiredRoles: ["ADMIN", "OCS_OPERATOR", "SEARCH_MANAGER"],
  //   },
  // },
  // {
  //   path: "/dictionaries/dictionary/en/list/:page?",
  //   name: "DictionaryEN",
  //   component: () =>
  //     import(
  //       /* webpackChunkName: "service" */ "../views/dictionary/dictionary/list/en/DictionaryEN.vue"
  //     ),
  //   meta: {
  //     title: "사전 관리",
  //     requiresAuth: true,
  //     requiredRoles: ["ADMIN", "OCS_OPERATOR", "SEARCH_MANAGER"],
  //   },
  // },
  // {
  //   path: "/dictionaries/dictionary/view/en/:dictId",
  //   name: "SingleDictionaryEN",
  //   component: () =>
  //     import(
  //       /* webpackChunkName: "service" */ "../views/dictionary/dictionary/single/en/SingleDictionaryEN.vue"
  //     ),
  //   meta: {
  //     navTree: ["Dictionary"],
  //     title: "사전 수정",
  //     requiresAuth: true,
  //     requiredRoles: ["ADMIN", "OCS_OPERATOR", "SEARCH_MANAGER"],
  //   },
  // },
  // {
  //   path: "/dictionaries/dictionary/view/:dictId",
  //   name: "SingleDictionary",
  //   component: () =>
  //     import(
  //       /* webpackChunkName: "service" */ "../views/dictionary/dictionary/single/SingleDictionary.vue"
  //     ),
  //   meta: {
  //     navTree: ["Dictionary"],
  //     title: "사전 수정",
  //     requiresAuth: true,
  //     requiredRoles: ["ADMIN", "OCS_OPERATOR", "SEARCH_MANAGER"],
  //   },
  // },
];
