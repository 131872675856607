const SELLER_TYPES = {
  FREE: "무료",
  PERSON: "개인",
  COMPANY: "법인사업자",
  PERSONAL_COMPANY: "개인사업자",
};

const CREATOR_GRADES = {
  NORMAL: { label: "NORMAL", color: "gray" },
  PRO: { label: "PRO", color: "yellow" },
  PARTNER: { label: "PARTNER", color: "blue" },
};

const CONTENT_QUALITY_GRADE = {
  STANDARD: { label: "STANDARD", color: "gray" },
  SPECIAL: { label: "SPECIAL", color: "yellow" },
  BAD: { label: "BAD", color: "red" },
};

// const ADMIN_ROLES = {
//   META_DATA_MANAGER: '메타데이터 관리자',
//   SEARCH_MANAGER: '검색어 관리자',
//   REVIEWER: '심사자',
//   OCS_OPERATOR: '운영자',
//   ADMIN: '마스터 관리자'
// }

const REVIEW_STATUS = {
  REVIEW: { label: "심사중", color: "green" },
  RETRY: { label: "재심사", color: "yellow" },
  CANCELED: { label: "심사취소", color: "yellow" },
  REJECTED: { label: "심사반려", color: "red" },
  PAUSED: { label: "심사보류", color: "green" },
  DONE: { label: "심사완료", color: "blue" },
};

// REVIEW: {label: '심사중', color: 'green'},
// CANCELED: {label: '재심사', color: 'yellow'},
// REJECTED: {label: '심사반려', color: 'red'},
// PAUSED: {label: '심사보류', color: 'green'},
// DONE: {label: '심사요청', color: 'blue'},
const CONTENT_STATUS = {
  REVIEW: { label: "심사중", color: "green" },
  RETRY: { label: "재심사", color: "yellow" },
  SALE: { label: "판매중", color: "green" },
  SALE_STOP: { label: "판매중지", color: "black" },
  SALE_REVIEW: { label: "판매검토", color: "blue" },
  DELETED: { label: "삭제됨", color: "black" },
  REJECTED: { label: "심사반려", color: "red" },
  BLOCKED: { label: "차단", color: "black" },
  CANCELED: { label: "심사취소", color: "black" },
  PAUSED: { label: "심사보류", color: "yellow" },
  WAIT_SALE: { label: "예약", color: "yellow" },
};

const EMAIL_STATUS = {
  RESERVED: { label: "미발송", color: "blue" },
  DONE: { label: "발송완료", color: "yellow" },
  DOING: { label: "발송중", color: "purple" },
  SAVED: { label: "임시저장", color: "green" },
  REQUESTED: { label: "확정", color: "purple" },
};

const SALES_MARKETS = {
  NOM: "네이버 OGQ 마켓",
  AOM: "아프리카 OGQ 마켓",
  OGQD: "OGQ Developers",
  PIC: "Picreative",
  RCS: "채팅+ OGQ 마켓",
  YH: "연합뉴스",
  YAY: "YayImage",
  ND: "엔디소프트오지큐마켓",
  EVENT: "Event Market",
};

const CONTENT_TYPES_SINGLE = {
  stickers: "STICKER",
  images: "STOCK_IMAGE",
  audios: "AUDIO",
  coloringSheets: "COLORING_SHEET",
  virtualAssets: "VIRTUAL_ASSETS",
};

const CONTENT_TYPES_PLURAL = {
  STICKER: "stickers",
  STOCK_IMAGE: "images",
  AUDIO: "audios",
  COLORING_SHEET: "coloringSheets",
  VIRTUAL_ASSETS: "virtualAssets",
};

const getContentTypeDisplayData = (contentType) => {
  return CONTENT_TYPES_SINGLE[contentType] || CONTENT_TYPES_PLURAL[contentType];
};

const getSellerTypeDisplayData = (sellerType) => {
  return SELLER_TYPES[sellerType]
    ? SELLER_TYPES[sellerType]
    : { label: sellerType };
};
const getCreatorGradeDisplayData = (creatorGrade) => {
  return CREATOR_GRADES[creatorGrade]
    ? CREATOR_GRADES[creatorGrade]
    : { label: creatorGrade };
};

const getContentQualityGradeDisplayData = (qualityGrade) => {
  return CONTENT_QUALITY_GRADE[qualityGrade]
    ? CONTENT_QUALITY_GRADE[qualityGrade]
    : { label: qualityGrade };
};

const getReviewStatusDisplayData = (status) => {
  return REVIEW_STATUS[status] ? REVIEW_STATUS[status] : { label: status };
};

const getContentStatusDisplayData = (status) => {
  return CONTENT_STATUS[status] ? CONTENT_STATUS[status] : { label: status };
};

const getReviewStatusListData = () => {
  return Object.keys(REVIEW_STATUS)
    .filter((s) => s !== "CANCELED")
    .map((k) => {
      return {
        value: k,
        label: REVIEW_STATUS[k].label,
      };
    });
};

const getEmailStatus = (emailStatus) => {
  return EMAIL_STATUS[emailStatus] || { label: emailStatus };
};

const getSalesMarket = (marketName) => {
  return SALES_MARKETS[marketName] || "OCS";
};

const getSubscriptionType = (salesTypes) => {
  return !salesTypes.includes("SINGLE") ? "구독 판매" : "일반 판매";
};

export {
  getCreatorGradeDisplayData,
  getContentQualityGradeDisplayData,
  getSellerTypeDisplayData,
  getReviewStatusDisplayData,
  getReviewStatusListData,
  getContentStatusDisplayData,
  getContentTypeDisplayData,
  getEmailStatus,
  getSalesMarket,
  getSubscriptionType,
};
