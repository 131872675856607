<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g fill="none" fill-rule="evenodd">
      <path d="M0 0h16v16H0z" />
      <path
        d="m4 4 8 8m0-8-8 8"
        stroke="#ADB5BD"
        stroke-linecap="square"
        stroke-linejoin="round"
        stroke-width="1.8"
      />
    </g>
  </svg>
</template>
