<template>
  <h1>
    <slot />
  </h1>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
h1 {
  display: flex;
  align-items: center;
  margin: 0;
  // font-size: 2rem;
  font-size: 24px;
  font-weight: 700;
  line-height: 1;
  color: var(--mono-900);
}
h1::v-deep(div) {
  margin-left: 12px;
}
</style>
