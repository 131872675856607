<template>
  <div 
    :class="[
      'toast',
      `toast-${type}`
    ]"
  >
    {{message}}
  </div>
</template>

<script>
export default {
  props: {
    message: {
      type: String
    },
    type: {
      type: String,
      default: 'default',
      validator: v=> [
        'default',
        'info',
        'error',
        'warning',
        'success'
      ].includes(v)
    }
  }
}
</script>

<style lang="scss" scoped>
.toast {
  --toast-width: 320px;
  --toast-gutter-v: 16px;
  --toast-gutter-h: 20px;
  --toast-font-color: var(--mono-000);
  --toast-font-size: 14px;
  --toast-bg-color: var(--mono-700);
  cursor: pointer;
  width: var(--toast-width);
  padding: var(--toast-gutter-v) var(--toast-gutter-h);
  background-color: var(--toast-bg-color);
  box-shadow: 0px 8px 10px rgba(27, 34, 63, 0.1);
  color: var(--toast-font-color);
  font-size: var(--toast-font-size);
}

.toast-default {
  --toast-bg-color: var(--mono-700);
}

.toast-success {
  --toast-bg-color: var(--green);
}
.toast-warning {
  --toast-bg-color: var(--yellow);
}
.toast-error {
  --toast-bg-color: var(--red);
}

.toast + .toast {
  margin-top: 10px;
}

</style>