export default [
  // CONTENTS
  {
    path: "/contents/contents/:category/list/:page?",
    name: "Contents",
    component: () =>
      import(
        /* webpackChunkName: "service" */ "../views/contents/contents/list/Contents.vue"
      ),
    meta: {
      title: "콘텐츠 목록",
      requiresAuth: true,
      requiredRoles: ["ADMIN", "OCS_OPERATOR"],
    },
  },
  {
    path: "/contents/content/:category/view/:contentId",
    name: "SingleContent",
    component: () =>
      import(
        /* webpackChunkName: "service" */ "../views/contents/contents/single/SingleContent.vue"
      ),
    meta: {
      navTree: ["Contents"],
      title: "콘텐츠 상세",
      requiresAuth: true,
      requiredRoles: ["ADMIN", "OCS_OPERATOR", "REVIEWER"],
    },
  },
  // REVIEWS
  {
    path: "/contents/reviews/:type/list/:page?",
    name: "ContentReviews",
    component: () =>
      import(
        /* webpackChunkName: "service" */ "../views/contents/reviews/list/ContentReviews.vue"
      ),
    meta: {
      title: "콘텐츠 심사",
      requiresAuth: true,
      requiredRoles: ["ADMIN", "OCS_OPERATOR", "REVIEWER"],
    },
  },
  {
    path: "/contents/review/:type/view/:reviewId",
    name: "SingleContentReview",
    component: () =>
      import(
        /* webpackChunkName: "service" */ "../views/contents/reviews/single/SingleContentReview.vue"
      ),
    meta: {
      navTree: ["ContentReviews"],
      title: "작품 상세보기",
      requiresAuth: true,
      requiredRoles: ["ADMIN", "OCS_OPERATOR", "REVIEWER"],
    },
  },
  // CATEGORIES
  {
    path: "/contents/categories/:type/",
    name: "Categories",
    component: () =>
      import(
        /* webpackChunkName: "service" */ "../views/contents/categories/list/Categories.vue"
      ),
    meta: {
      title: "카테고리 관리",
      requiresAuth: true,
      requiredRoles: ["ADMIN", "OCS_OPERATOR"],
    },
  },
  // METADATA
  {
    path: "/contents/metadata/:type/list/:page?",
    name: "Metadata",
    component: () =>
      import(
        /* webpackChunkName: "service" */ "../views/contents/metadata/list/Metadata.vue"
      ),
    meta: {
      title: "메타 데이터 관리",
      requiresAuth: true,
      requiredRoles: ["ADMIN", "OCS_OPERATOR", "META_DATA_MANAGER"],
    },
  },
  {
    path: "/contents/metadata/:type/view/:metadataId",
    name: "SingleMetadata",
    component: () =>
      import(
        /* webpackChunkName: "service" */ "../views/contents/metadata/single/SingleMetadata.vue"
      ),
    meta: {
      navTree: ["Metadata"],
      title: "작품 상세보기",
      requiresAuth: true,
      requiredRoles: ["ADMIN", "OCS_OPERATOR", "META_DATA_MANAGER"],
    },
  },
  // CONTENT UPLOAD
  {
    path: "/contents/upload",
    name: "ContentUpload",
    component: () =>
      import(
        /* webpackChunkName: "service" */ "../views/contents/upload/ContentUpload.vue"
      ),
    meta: {
      title: "직접 업로드",
      requiresAuth: true,
      requiredRoles: ["ADMIN", "OCS_OPERATOR"],
    },
  },
  // CONTENT UPLOAD
  {
    path: "/contents/upload/stickers",
    name: "ContentUploadStickers",
    component: () =>
      import(
        /* webpackChunkName: "service" */ "../views/contents/upload/stickers/ContentUploadStickers.vue"
      ),
    meta: {
      title: "직접 업로드",
      requiresAuth: true,
      requiredRoles: ["ADMIN", "OCS_OPERATOR"],
    },
  },
  // CONTENT UPLOAD
  {
    path: "/contents/upload/animated",
    name: "ContentUploadAnimatedStickers",
    component: () =>
      import(
        /* webpackChunkName: "service" */ "../views/contents/upload/stickers/ContentUploadAnimatedStickers.vue"
      ),
    meta: {
      title: "직접 업로드",
      requiresAuth: true,
      requiredRoles: ["ADMIN", "OCS_OPERATOR"],
    },
  },
  // CONTENT UPLOAD
  {
    path: "/contents/upload/image",
    name: "ContentUploadImage",
    component: () =>
        import(
            /* webpackChunkName: "service" */ "../views/contents/upload/image/ContentUploadImage.vue"
            ),
    meta: {
      title: "직접 업로드",
      requiresAuth: true,
      requiredRoles: ["ADMIN", "OCS_OPERATOR"],
    },
  },
  // CONTENT UPLOAD
  {
    path: "/contents/upload/source",
    name: "ContentUploadImageSource",
    component: () =>
        import(
            /* webpackChunkName: "service" */ "../views/contents/upload/image/ContentUploadImageSource.vue"
            ),
    meta: {
      title: "직접 업로드",
      requiresAuth: true,
      requiredRoles: ["ADMIN", "OCS_OPERATOR"],
    },
  },
];
