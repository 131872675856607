export default [
  // Admin Routes
  {
    path: "/admin/list/:page?",
    name: "Admin",
    component: () =>
      import(/* webpackChunkName: "service" */ "../views/admin/Admin.vue"),
    meta: {
      title: "사용자 관리",
      requiresAuth: true,
      requiredRoles: ["ADMIN"],
    },
  },
  {
    path: "/admin/create",
    name: "AdminCreate",
    component: () =>
      import(
        /* webpackChunkName: "service" */ "../views/admin/AdminCreate.vue"
      ),
    meta: {
      navTree: ["Admin"],
      title: "사용자 추가",
      requiresAuth: true,
      requiredRoles: ["ADMIN"],
    },
  },
];
