import { getCookieString, redirectToLogin } from "../utils/auth";

import axios from "axios";

export const createApiClient = (isOID = false, isNotOCP = false) => {
  const [_, menu, category] = window.location.pathname.split("/");
  const isOCPServer =
    menu === "contents" ||
    category === "metadata" ||
    menu === "creators" ||
    isOID;

  // TODO: isNotOCP는 임시 인자. 추후 삭제 예정
  const BASE_URLS = {
    STG_OCS: "https://stg-api.creators.ogq.me",
    STG_OCP: "https://stg.oapi.ogq.me",
    PRD_OCS: "https://api.ocs.ogq.me",
    PRD_OCP: "https://oapi.ogq.me",
  };
  const getBaseUrl = () => {
    const env = process.env.NODE_ENV === "production" ? "PRD" : "STG";
    const serverType = isOCPServer ? "OCP" : "OCS";
    const notOCP = isNotOCP ? "OCS" : "OCP";

    return BASE_URLS[`${env}_${serverType === "OCP" ? notOCP : serverType}`];
  };

  const APIClient = axios.create({
    baseURL: getBaseUrl(),
    headers: {
      "X-OGQ-SERVICE-ID": "OCS_ADMIN",
      "Content-Type": "application/json",
    },
    withCredentials: true,
  });

  // const addRequestInterceptor = (axiosInstance) => {
  //   axiosInstance.interceptors.request.use(
  //     (req) => req,
  //     (err) => Promise.reject(err)
  //   );
  // };

  const addResponseInterceptor = (axiosInstance) => {
    axiosInstance.interceptors.response.use(
      (res) => {
        return res;
      },
      (err) => {
        switch (err.response?.status) {
          case 401: {
            redirectToLogin();
            break;
          }
          case 403: {
            alert("access denied");
            break;
          }
          case 404: {
            alert("page not found");
            break;
          }
          default:
            return Promise.reject(err);
        }
      }
    );
  };

  // addRequestInterceptor(APIClient);
  addResponseInterceptor(APIClient);

  return APIClient;
};
