export default [
  {
    name: "service",
    label: "서비스",
    allow: ["ADMIN", "OCS_OPERATOR"],
    list: [
      {
        label: "공지사항",
        name: "notice",
        routeName: "Notice",
        activeRoutes: ["Notice", "NoticeAdd", "NoticeEdit"],
      },
      {
        label: "팝업 관리",
        name: "popup",
        routeName: "Popup",
        activeRoutes: ["Popup", "PopupAdd", "PopupEdit"],
      },
      {
        label: "이메일 관리",
        name: "mail",
        routeName: "MailManual",
        activeRoutes: [
          "MailManual",
          "MailManualAdd",
          "MailManualEdit",
          "MailAuto",
          "MailAutoAdd",
        ],
      },
      {
        label: "알림 관리",
        name: "notifications",
        routeName: "NotificationsManual",
        activeRoutes: [
          "NotificationsManual",
          "NotificationsManualAdd",
          "NotificationsAuto",
          "NotificationsAutoAdd",
        ],
      },
      {
        label: "배너 관리",
        name: "banner",
        routeName: "Banner",
        activeRoutes: ["Banner", "BannerAdd", "BannerEdit"],
      },
      {
        label: "약관 관리",
        name: "policy",
        routeName: "Policy",
        activeRoutes: ["Policy", "PolicyAdd", "PolicyEdit"],
      },
    ],
  },
  {
    name: "contents",
    label: "콘텐츠",
    allow: ["ADMIN", "OCS_OPERATOR", "REVIEWER", "META_DATA_MANAGER"],
    list: [
      {
        label: "콘텐츠 목록",
        name: "contents",
        routeName: "Contents",
        routeParams: { category: "stickers" },
        activeRoutes: ["Contents", "SingleContent"],
        disallow: ["REVIEWER", "META_DATA_MANAGER"],
      },
      {
        label: "콘텐츠 심사",
        name: "contentReviews",
        routeName: "ContentReviews",
        routeParams: { type: "STICKER" },
        activeRoutes: ["ContentReviews", "SingleContentReview"],
        disallow: ["META_DATA_MANAGER"],
      },
      {
        label: "메타데이터 관리",
        name: "metadata",
        routeName: "Metadata",
        routeParams: { type: "STICKER" },
        activeRoutes: ["Metadata", "SingleMetadata"],
        disallow: ["REVIEWER"],
      },
      {
        label: "카테고리 관리",
        name: "categories",
        routeName: "Categories",
        routeParams: { type: "STICKER" },
        activeRoutes: ["Categories"],
        disallow: ["REVIEWER", "META_DATA_MANAGER"],
      },
      {
        label: "콘텐츠 업로드",
        name: "contentUpload",
        routeName: "ContentUpload",
        activeRoutes: ["ContentUpload", "ContentUploadStickers", "ContentUploadAnimatedStickers", "ContentUploadImage", "ContentUploadImageSource"],
        disallow: ["REVIEWER", "META_DATA_MANAGER"],
      },
    ],
  },
  {
    name: "creators",
    label: "크리에이터",
    allow: ["ADMIN", "OCS_OPERATOR"],
    list: [
      {
        label: "크리에이터 목록",
        name: "creators",
        routeName: "Creators",
        activeRoutes: ["Creators", "SingleCreator"],
      },
      {
        label: "크리에이터 생성",
        name: "creatorAdd",
        routeName: "CreatorAdd",
        activeRoutes: ["CreatorAdd"],
      },
    ],
  },
  // {
  //   name: "dictionary",
  //   label: "검색어",
  //   allow: ["ADMIN", "OCS_OPERATOR", "SEARCH_MANAGER"],
  //   list: [
  //     {
  //       label: "사전 관리",
  //       name: "dictionary",
  //       routeName: "Dictionary",
  //       activeRoutes: ["Dictionary", "DictionaryEN"],
  //     },
  //   ],
  // },
  {
    name: "admin",
    label: "관리자",
    allow: ["ADMIN"],
    list: [
      {
        label: "계정 관리",
        name: "accounts",
        routeName: "Admin",
        activeRoutes: ["Admin", "AdminCreate"],
      },
    ],
  },
  {
    name: "faq",
    label: "자주 묻는 질문",
    allow: ["ADMIN", "OCS_OPERATOR"],
    list: [
      {
        label: "자주 묻는 질문 관리",
        name: "faq",
        routeName: "Faq",
        activeRoutes: ["Faq", "FaqAdd"],
      },
    ],
  },
];
