export default [
  // Faq Routes
  {
    path: "/faq",
    name: "Faq",
    component: () =>
      import(/* webpackChunkName: "service" */ "../views/faq/Faq.vue"),
    meta: {
      title: "자주 묻는 질문 관리",
      requiresAuth: true,
      requiredRoles: ["ADMIN", "OCS_OPERATOR"],
    },
  },
  {
    path: "/faq/add",
    name: "FaqAdd",
    component: () => import(/* webpackChunkName: "service" */ "../views/faq/FaqAdd.vue"),
    meta: { navTree: ["Faq"], title: "항목 추가", requiresAuth: true },
  },
  {
    path: "/faq/edit/:faqId",
    name: "FaqEdit",
    component: () => import(/* webpackChunkName: "service" */ "../views/faq/FaqAdd.vue"),
    meta: {
      navTree: ["Faq"],
      title: "항목 수정",
      requiresAuth: true,
    },
  },
];
