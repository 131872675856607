import { createApp } from "vue";
import ImageViewer from "@/components/imageViewer/ImageViewer";

const Viewer = {
  install(app) {
    const instance = createApp(ImageViewer).mount(
      document.createElement("div")
    );
    app.config.globalProperties.$imageViewer = {
      open({index=0, images=[], mode=false, replaceUrl}) {
        document.body.appendChild(instance.$el);
        instance.isOpen = true
        instance.currentIndex = index || 0
        instance.images = (images || []).map((image)=> {
          return {
            ...image,
            isReplaced: false
          }
        })
        
        instance.mode = mode
        instance.replaceUrl = replaceUrl
        return instance;
      },
      close() {
        if (instance && instance.isOpen) {
          instance.close()
          instance.images = []
          instance.currentIndex = 0
        }
      }
    };
  },
};

export default Viewer;
