<template>
  <div class="images-list" v-if="images.length > 1">
    <div 
      class="image-item"
      v-for="image, i in images"
      v-on:click="$emit('selectIndex', i)"
      :key="i"
    >
      <img
        :src="image.url"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    images: {
      type: Array,
      validator: ()=> true
    },
    currentIndex: {
      type: Number
    }
  }
}
</script>

<style lang="scss" scoped>

.images-list {
  position: absolute;
  bottom: 50px;
  left: 0;
  width: 100%;
  z-index: 3;
  display: flex;
  padding: 8px 0;
  overflow: auto;
}

.image-item {
  flex: none;
  display: flex;
  width: 80px;
  height: 80px;
  $s: 16px;
  background: repeating-conic-gradient(#e5e5e5 0% 25%, white 0% 50%) 50% / #{$s
    $s};
  margin: 0 4px;
  flex-wrap: nowrap;
  white-space: nowrap;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

</style>