import { createRouter, createWebHistory, useRouter } from "vue-router";
import store from "@/store";
import Home from "../views/Home.vue";

import ServiceRoutes from "./routes-service";
import CreatorRoutes from "./routes-creator";
import AdminRoutes from "./routes-admin";
import ContentRoutes from "./routes-contents";
import DictionaryRoutes from "./routes-dictionaries";
import FaqRoutes from "./routes-faq";

const routes = [
  // 404, 403 페이지 주석 (나중에 필요한 경우 복구)
  // {
  //   path: "/:pathMatch(.*)*",
  //   name: "PageNotFound",
  //   component: () =>
  //     import(
  //       /* webpackChunkName: "PageNotFound" */ "../views/PageNotFound.vue"
  //     ),
  // },
  // {
  //   path: "/access-denied",
  //   name: "AccessDenied",
  //   component: () =>
  //     import(
  //       /* webpackChunkName: "PageNotFound" */ "../views/AccessDenied.vue"
  //     ),
  // },
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      title: "홈",
      requiresAuth: true,
    },
  },
  ...ServiceRoutes,
  ...ContentRoutes,
  ...CreatorRoutes,
  ...AdminRoutes,
  ...DictionaryRoutes,
  ...FaqRoutes,
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

// pathname이 존재하는 route와 매치되는지 체크
const checkRouteExists = (pathname) => {
  const { matched } = router.resolve(pathname);
  return !!matched.length;
};

router.beforeEach((to, _from, next) => {
  // 존재하지 않는 페이지 주소로 진입시, origin으로 이동
  if (!checkRouteExists(to.path)) {
    window.location.replace(window.location.origin);
  }
  const isRoleAllowed = to.meta.requiredRoles
    ? store.getters["user/userIsInRole"](to.meta.requiredRoles)
    : true;
  if (!isRoleAllowed) {
    alert("access denied!");
    return;
  }
  next();
});

export default router;
