let i = 0
const duration = 7500

export default {
  namespaced: true,
  state: {
    toasts: []
  },
  getters: {
    get({toasts}) {
      return toasts
    }
  },
  mutations: {
    ADD_TOAST(state, {message, type, id}) {
      state.toasts.push({
        message, 
        type, 
        id,
      })
    },
    REMOVE_TOAST_BY_ID(state, id) {
      state.toasts = state.toasts.filter((toast)=> {
        return toast.id !== id
      })
    }
  },
  actions: {
    create({commit}, {message, type}) {
      const id = i
      commit("ADD_TOAST", {
        message, type, id
      })
      setTimeout(()=> {
        commit("REMOVE_TOAST_BY_ID", id)
      }, duration)
      i++
    }
  }
}