import { apiGet } from "../api/apiMethods";

export default {
  namespaced: true,
  state: () => ({
    currentUser: false,
  }),
  getters: {
    getCurrentUser({ currentUser }) {
      return currentUser;
    },
    getUserRole({ currentUser }) {
      return currentUser.roles;
    },
    userIsInRole:
      ({ currentUser }) =>
      (roles = []) => {
        return currentUser
          ? currentUser.roles.some((v) => roles.includes(v))
          : false;
      },
  },
  mutations: {
    setCurrentUser(state, user) {
      state.currentUser = user;
    },
  },
  actions: {
    async getUserInfo({ state, commit }) {
      try {
        const result = await apiGet("/acc/v1/oapi/users/me");
        if (result.status === 200) {
          commit("setCurrentUser", result.data);
          console.log(
            "Logged in as",
            JSON.parse(JSON.stringify(state.currentUser))
          );
        }
      } catch (err) {
        console.log("Get User Info Error: ", err, err.response);
        commit("setCurrentUser", false);
      }
    },
    logout() {
      const LOGOUT_BASE_URL =
        (process.env.NODE_ENV !== "production"
          ? "https://stg.oid.ogq.me"
          : "https://oid.ogq.me") + "/oid/v1/web/sign-out";

      const logoutUrl = `${LOGOUT_BASE_URL}?retUrl=${window.location.origin}`;
      window.location.replace(logoutUrl);
    },
  },
};
