<template>
  <router-view />
</template>

<script>
import "@ogqcorp/v-ui/dist/ogq.css"
export default {
  watch: {
    $route() {
      this.$imageViewer.close()
    }
  }
}
</script>


<style lang="scss">
button {
  cursor: pointer;
}
// @import "~@/styles/_variables.scss";
// @import "~@/styles/_main.scss";
</style>
