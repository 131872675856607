<template>
  <TransitionGroup
    class="toasts"
    tag="div"
    name="list"
  >
    <Toast
      v-for="toast in toasts"
      :message="toast.message"
      :type="toast.type"
      :key="toast.id"
      v-on:click="removeById(toast.id)"
    />  
  </TransitionGroup>
</template>

<script>
import Toast from '@/components/toast/Toast'

export default {
  components: {
    Toast
  },
  methods: {
    removeById(id) {
      this.$store.commit("toast/REMOVE_TOAST_BY_ID", id)
    }
  },
  computed: {
    toasts() {
      return this.$store.getters['toast/get']
    }
  }
}
</script>


<style lang="scss" scoped>

.toasts {
  position: fixed;
  bottom: 16px;
  right: 16px;
  z-index: 99999;
}

.list-move,
.list-enter-active,
.list-leave-active {
  transition: all 240ms cubic-bezier(0.075, 0.82, 0.165, 1);
}

.list-enter-from {
  opacity: 0;
  // transform: translate3d(100%, 0, 0);
}

.list-leave-to {
  position: absolute;
  opacity: 0;
}

.list-leave-active {
  // position: absolute;
}

</style>