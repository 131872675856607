<template>
  <div
    :class="[
      'image-wrapper',
      `image-mode-${mode ? mode : 'default'}`,
      { 'image-darkmode': isDarkmode },
    ]"
    ref="wrapper"
  >
    <div class="image image-base" ref="image">
      <img
        v-if="currentImage"
        :src="currentImage.url"
        :alt="currentImage.name || ''"
      />
    </div>
    <div class="image image-hovered" v-if="currentImage.hoverUrl">
      <img :src="currentImage.hoverUrl" />
    </div>
    <div
      class="image image-alternate"
      v-if="currentImage.alternateUrl"
      :class="{ show: isAlternate }"
    >
      <img :src="currentImage.alternateUrl" />
    </div>
    <div
      v-if="image && mode && mode !== 'stickers'"
      :class="['magnifier', { show: showMagnifier }]"
      :style="magnifierStyle"
    >
      <canvas ref="canvas"> </canvas>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      image: null,
      showMagnifier: false,
      magnifierPos: {
        x: 0,
        y: 0,
      },
      magnifierSize: 120,
      zoomFactor: 0.5,
    };
  },
  props: ["isAlternate", "isDarkmode", "currentImage", "currentIndex", "mode"],
  watch: {
    currentIndex() {
      console.log("Index");
      this.loadReplace();
    },
    showMagnifier(n) {
      console.log("magnifier", n);
    },
  },
  mounted() {
    console.log("ImageViewerImage - created");
    document.addEventListener("mousemove", this.onMouseMove, false);
  },
  beforeUnmount() {
    document.removeEventListener("mousemove", this.onMouseMove, false);
  },
  methods: {
    formatImageUrl(url, format) {
      return this.$getImgUrl(url, format);
    },
    onMouseMove(e) {
      if (!this.image || this.mode === "stickers") return;

      // const windowWidth = window.innerWidth
      // const windowHeight = window.innerHeight
      // const imageWidth = this.$refs.image.offsetWidth
      // const imageHeight = this.$refs.image.offsetHeight

      const imageRect = this.$refs.image.getBoundingClientRect();
      const imageX = e.clientX - imageRect.left;
      const imageY = e.clientY - imageRect.top;

      if (
        imageX < 0 ||
        imageX > imageRect.width ||
        imageY < 0 ||
        imageY > imageRect.height
      ) {
        this.showMagnifier = false;
      } else {
        this.showMagnifier = true;
      }

      const mouseXInImage = Math.max(
        Math.min(imageX, imageRect.width - this.magnifierSize * 0.5),
        this.magnifierSize * 0.5
      );
      const mouseYInImage = Math.max(
        Math.min(imageY, imageRect.height - this.magnifierSize * 0.5),
        this.magnifierSize * 0.5
      );

      this.magnifierPos.x = mouseXInImage;
      this.magnifierPos.y = mouseYInImage;

      const mouseXInPercent = Math.max(
        Math.min(mouseXInImage / imageRect.width, 1),
        0
      );
      const mouseYInPercent = Math.max(
        Math.min(mouseYInImage / imageRect.height, 1),
        0
      );

      const canvas = this.$refs.canvas;
      const ctx = canvas.getContext("2d");
      canvas.width = this.magnifierSize;
      canvas.height = this.magnifierSize;

      const image = this.image;

      const originalWidth = image.width;
      const originalHeight = image.height;
      const cropOriginalSize =
        (this.magnifierSize / imageRect.width) * originalWidth;

      ctx.drawImage(
        image,
        mouseXInPercent * originalWidth,
        mouseYInPercent * originalHeight,
        cropOriginalSize * this.zoomFactor,
        cropOriginalSize * this.zoomFactor,
        0,
        0,
        this.magnifierSize,
        this.magnifierSize
      );

      // console.log(
      //   (mouseXInPercent * originalWidth) - (cropOriginalSize * 0.5),
      //   (mouseYInPercent * originalHeight) - (cropOriginalSize * 0.5),
      //   '---',
      //   (mouseXInPercent * originalWidth) - (cropOriginalSize * 0.5),
      //   (mouseYInPercent * originalHeight) - (cropOriginalSize * 0.5),

      //   {cropOriginalSize, ref: this.magnifierSize, originalWidth, width: imageRect.width}
      // )
      // console.log(mouseXInPercent, mouseYInPercent)
    },
    async loadReplace() {
      console.log("Load replace", this.currentImage);
      if (
        this.currentImage.replaceUrl == null ||
        typeof this.currentImage.replaceUrl !== "function" ||
        this.currentImage.isReplaced
      )
        return;

      this.image = null;

      const imageUrl = await this.currentImage.replaceUrl();

      if (imageUrl) {
        console.log("Emit Load Replace imageUrl", imageUrl, this.currentIndex);
        const image = new Image();
        image.onload = () => {
          this.image = image;
        };
        image.src = imageUrl;
        this.$emit("replaceUrl", { imageUrl, index: this.currentIndex });
      }
    },
  },
  computed: {
    magnifierStyle() {
      return {
        transform: `translate3d(${this.magnifierPos.x}px, ${this.magnifierPos.y}px, 0)`,
        marginLeft: `-${this.magnifierSize * 0.5}px`,
        marginTop: `-${this.magnifierSize * 0.5}px`,
        width: `${this.magnifierSize}px`,
        height: `${this.magnifierSize}px`,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.image-wrapper {
  position: relative;
  display: flex;
  width: fit-content;
  $s: 16px;
  background: repeating-conic-gradient(#e5e5e5 0% 25%, white 0% 50%) 50% / #{$s
    $s};
  overflow: hidden;
}

.image {
  flex: none;
  max-width: 100%;
  max-height: 100%;
  display: flex;
}

.image-base img {
  flex: 1;
  max-width: 100vw;
  max-height: calc(100vh - 80px);
}

.image-hovered,
.image-alternate {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 2;
}

.image-hovered img,
.image-alternate img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.image-alternate.show {
  opacity: 1;
}

.image-darkmode {
  background: #242a2f;
}

.image-wrapper:hover .image-hovered {
  opacity: 1;
}

.image-stickers.image-wrapper {
  width: 240px;
  height: 240px;

  img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
  }
}

.magnifier {
  position: absolute;
  top: 0;
  left: 0;

  z-index: 10;

  opacity: 0;

  &.show {
    opacity: 1;
  }

  canvas {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
  }
}
</style>
