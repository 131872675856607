<template>
  <div class="image-viewer-outer">
    <div class="overlay"></div>
    <div
      class="image-viewer"
      v-if="currentImage"
      :class="[`mode-${mode ? mode : 'default'}`]"
    >
      <div class="image-viewer-actions">
        <div class="meta">
          <div class="name">
            {{ currentImage.name || "" }}
          </div>
        </div>
        <div class="actions">
          <div class="toggle-action" v-if="mode === 'stickers'">
            다크모드
            <Toggle v-model="isDarkmode" class="toggle" />
          </div>
          <div class="toggle-action" v-else-if="mode === 'coloringSheets'">
            컬러링 전 후
            <Toggle v-model="isAlternate" class="toggle" />
          </div>
        </div>
      </div>

      <div class="image-wrapper-rail">
        <button
          v-if="images.length > 1"
          v-on:click="setPrevIndex"
          class="btn-nav"
        >
          <i-arrow-left />
        </button>
        <image-viewer-image
          v-if="currentImage"
          v-on:replaceUrl="replaceUrlByIndex"
          :mode="mode"
          :currentIndex="currentIndex"
          :currentImage="currentImage"
          :isDarkmode="isDarkmode"
          :isAlternate="isAlternate"
          ref="image"
        />
        <button
          v-if="images.length > 1"
          v-on:click="setNextIndex"
          class="btn-nav"
        >
          <i-arrow-right />
        </button>
      </div>

      <Button
        v-if="['stickers'].includes(mode)"
        class="btn-view-original"
        :externalUrl="currentImage.url"
      >
        원본보기
      </Button>
    </div>
    <button class="btn-close" v-on:click="close">
      <i-cross />
    </button>

    <images-list
      v-if="mode !== 'coloringSheets' && mode !== 'virtualAssets'"
      :images="images"
      :currentIndex="currentIndex"
      v-on:selectIndex="setCurrentIndex"
    />
  </div>
</template>

<script>
import svgCross from "@/components/svg/icons/svgCross";
import svgArrowLeft from "@/components/svg/icons/svgArrowLeft";
import svgArrowRight from "@/components/svg/icons/svgArrowRight";
import { Button, Toggle } from "@ogqcorp/v-ui";
import ImageViewerList from "./ImageViewerList";
import ImageViewerImage from "./ImageViewerImage";

export default {
  data() {
    return {
      isOpen: false,
      images: [],
      mode: false,
      currentIndex: 0,
      isDarkmode: false,
      isAlternate: false,
    };
  },
  mounted() {
    console.log("ImageViewer:mounted - images", this.images);
  },
  watch: {
    currentImage() {
      this.$nextTick(() => {
        if (this.$refs.image) {
          this.$refs.image.loadReplace();
        }
      });
    },
    isAlternate(n) {
      this.currentIndex = n ? 1 : 0;
    },
  },
  methods: {
    close() {
      this.destroy();
    },
    destroy() {
      if (this.isOpen && this.$el) {
        this.$el.parentNode.removeChild(this.$el);
        this.isOpen = false;
      }
    },
    setCurrentIndex(i) {
      this.currentIndex = i;
    },
    setPrevIndex() {
      if (this.images.length <= 1) return;

      this.currentIndex =
        this.currentIndex === 0
          ? this.images.length - 1
          : this.currentIndex - 1;
    },
    setNextIndex() {
      if (this.images.length <= 1) return;

      this.currentIndex =
        this.currentIndex === this.images.length - 1
          ? 0
          : this.currentIndex + 1;
    },
    replaceUrlByIndex({ index, imageUrl }) {
      console.log("Replace url", index, imageUrl);
      this.images.splice(index, 1, {
        ...this.images[index],
        isReplaced: true,
        url: imageUrl,
      });
    },
  },
  computed: {
    currentImage() {
      if (this.mode === "coloringSheets") {
        return this.isAlternate && this.images[1]
          ? this.images[1]
          : this.images[0];
      }
      return this.images[this.currentIndex];
    },
  },
  components: {
    "i-cross": svgCross,
    "i-arrow-left": svgArrowLeft,
    "i-arrow-right": svgArrowRight,
    "images-list": ImageViewerList,
    "image-viewer-image": ImageViewerImage,
    Button,
    Toggle,
  },
};
</script>

<style lang="scss" scoped>
.image-viewer-outer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 1;
}

.image-viewer {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  z-index: 2;
  max-width: 100%;
  max-height: 100%;
}

.image-viewer-actions {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 0 10px;
}

.image-viewer-actions .meta .name {
  color: white;
  font-size: 14px;
  font-weight: 500;
}

.image-viewer-actions .actions .toggle-action {
  display: flex;
  align-items: center;
  font-size: 12px;
  color: white;

  .toggle {
    margin-left: 10px;
  }
}

.image-wrapper-rail {
  position: relative;
}

.btn-nav {
  cursor: pointer;
  position: absolute;
  top: 0;
  bottom: 0;

  &:first-child {
    right: calc(100% + 10px);
  }
  &:last-child {
    left: calc(100% + 10px);
  }

  border: 0;
  outline: 0;
  background: transparent;

  svg {
    display: block;
    width: 40px;
    height: auto;
  }

  &:hover {
    background-color: rgba(white, 0.1);
  }
}

button.btn-view-original {
  width: 100%;
  margin-top: 8px;
}

button.btn-close {
  cursor: pointer;
  position: absolute;
  top: 32px;
  right: 32px;
  width: 48px;
  height: 48px;
  z-index: 3;
  padding: 0;
  display: flex;
  background-color: var(--mono-900);
  border: 0;
  opacity: 0.75;
  border-radius: 50%;
  svg {
    display: block;
    width: 24px;
    height: auto;
    margin: auto;
    &::v-deep(path[stroke]) {
      stroke: white;
    }
  }
  &:hover {
    opacity: 1;
  }
}
</style>
