import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import dayjs from "dayjs";
import { getCreatorGradeDisplayData } from "@/utils";
import { mapGetters } from "vuex";
import { Alert } from "@ogqcorp/v-ui";
import ImageViewer from "@/components/imageViewer";

// https://stackoverflow.com/questions/7837456/how-to-compare-arrays-in-javascript
Array.prototype.equals = function (array) {
  // if the other array is a falsy value, return
  if (!array) return false;

  // compare lengths - can save a lot of time
  if (this.length != array.length) return false;

  for (var i = 0, l = this.length; i < l; i++) {
    // Check if we have nested arrays
    if (this[i] instanceof Array && array[i] instanceof Array) {
      // recurse into the nested arrays
      if (!this[i].equals(array[i])) return false;
    } else if (this[i] != array[i]) {
      // Warning - two different object instances will never be equal: {x:20} != {x:20}
      return false;
    }
  }
  return true;
};
// Hide method from for-in loops
Object.defineProperty(Array.prototype, "equals", { enumerable: false });

const app = createApp(App);

app.config.globalProperties.$API_URL =
  process.env.NODE_ENV === "production"
    ? "https://api.ocs.ogq.me"
    : "https://stg-api.creators.ogq.me";
app.config.globalProperties.$formatDate = (date, formatStr = "YYYY-MM-DD") => {
  return dayjs(date).format(formatStr);
};
app.config.globalProperties.$formatTime = (time = new Date.getTime()) => {
  return Math.round(time / 1000);
};

app.config.globalProperties.$isObject = (obj) => {
  return obj instanceof Object && !(obj instanceof Array);
};

app.config.globalProperties.$getImgUrl = (url = "", format) => {
  let formatStr = format ? format : "c240_240";
  return `${url}?format=${formatStr}`;
};

app.config.globalProperties.$toJSON = (o) => {
  return JSON.parse(JSON.stringify(o));
};

app.config.globalProperties.$getCreatorGradeDisplayData = (creatorGrade) => {
  return getCreatorGradeDisplayData(creatorGrade);
};

(async () => {
  await store.dispatch("user/getUserInfo");
  app.mixin({
    computed: {
      ...mapGetters({
        currentUser: "user/getCurrentUser",
        userRole: "user/getUserRole",
      }),
    },
    methods: {
      userIsInRole(role) {
        const userRole = store.getters["user/getUserRole"];
        return role.includes(...userRole);
      },
    },
  });
  app
    .use(store)
    .use(router)
    .use(Alert, { router })
    .use(ImageViewer)
    .mount("#app");
})();
