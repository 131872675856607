import Cookies from "js-cookie";

export const redirectToLogin = () => {
  const LOGIN_BASE_URL =
    process.env.NODE_ENV !== "production"
      ? "https://stg.oid.ogq.me"
      : "https://oid.ogq.me";
  const loginUrl = `${LOGIN_BASE_URL}/?serviceId=OCS_ADMIN&callbackUrl=${window.location.href}`;
  window.location.replace(loginUrl);
};

export const getCookieString = () => {
  const cookies = Cookies.get();
  const cookieString = Object.entries(cookies)
    .map(([key, value]) => `${key}=${value}`)
    .join("; ");
  return cookieString;
};
