import { createApiClient } from "./apiClient";

// TODO: 컴포저블로 변경
export const makeApiCall = async (
  method,
  url,
  { data = null, params = {} } = {},
  isNotOCP = false
  // TODO: isNotOCP는 임시 인자 (파일업로드 api때문에 넣음). 추후 삭제 예정
) => {
  const isOID = url.includes("/acc/v1/oapi");
  const apiClient = createApiClient(isOID, isNotOCP);
  try {
    const config = method === "get" ? { params } : data;
    const response = await apiClient[method](url, config);
    return response;
  } catch (error) {
    console.error(`[Error][${method.toUpperCase()}] ${url} :`, error);
    throw error;
  }
};
export const apiGet = (url, params, isNotOCP) => makeApiCall("get", url, { params }, isNotOCP);
export const apiPost = (url, data, isNotOCP) =>
  makeApiCall("post", url, { data }, isNotOCP);
export const apiPut = (url, data, isNotOCP) => makeApiCall("put", url, { data }, isNotOCP);
export const apiDelete = (url, data, isNotOCP) => makeApiCall("delete", url, { data }, isNotOCP);
