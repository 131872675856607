export default [
  // Notice Routes
  {
    path: "/service/notice/:page?",
    name: "Notice",
    component: () =>
      import(
        /* webpackChunkName: "service" */ "../views/service/notice/Notice.vue"
      ),
    meta: {
      title: "공지사항",
      requiresAuth: true,
      requiredRoles: ["ADMIN", "OCS_OPERATOR"],
    },
  },
  {
    path: "/service/notice/add",
    name: "NoticeAdd",
    component: () =>
      import(
        /* webpackChunkName: "service" */ "../views/service/notice/NoticeAdd.vue"
      ),
    meta: {
      navTree: ["Notice"],
      title: "공지사항 작성",
      requiresAuth: true,
      requiredRoles: ["ADMIN", "OCS_OPERATOR"],
    },
  },
  {
    path: "/service/notice/edit/:noticedId",
    name: "NoticeEdit",
    component: () =>
      import(
        /* webpackChunkName: "service" */ "../views/service/notice/NoticeAdd.vue"
      ),
    meta: {
      navTree: ["Notice"],
      title: "공지사항 수정",
      requiresAuth: true,
      requiredRoles: ["ADMIN", "OCS_OPERATOR"],
    },
  },
  {
    path: "/service/notice/edit/:noticeId",
    name: "NoticeEdit",
    component: () =>
      import(
        /* webpackChunkName: "service" */ "../views/service/notice/NoticeAdd.vue"
      ),
    meta: {
      navTree: ["Notice"],
      title: "공지사항 수정",
      requiresAuth: true,
      requiredRoles: ["ADMIN", "OCS_OPERATOR"],
    },
  },
  // Popup
  {
    path: "/service/popup/:page?",
    name: "Popup",
    component: () =>
      import(
        /* webpackChunkName: "service" */ "../views/service/popup/Popup.vue"
      ),
    meta: {
      title: "팝업 관리",
      requiresAuth: true,
      requiredRoles: ["ADMIN", "OCS_OPERATOR"],
    },
  },
  {
    path: "/service/popup/add",
    name: "PopupAdd",
    component: () =>
      import(
        /* webpackChunkName: "service" */ "../views/service/popup/PopupAdd.vue"
      ),
    meta: {
      navTree: ["Popup"],
      title: "팝업 추가",
      requiresAuth: true,
      requiredRoles: ["ADMIN", "OCS_OPERATOR"],
    },
  },
  {
    path: "/service/popup/edit/:popupId",
    name: "PopupEdit",
    component: () =>
      import(
        /* webpackChunkName: "service" */ "../views/service/popup/PopupAdd.vue"
      ),
    meta: {
      navTree: ["Popup"],
      title: "팝업 수정",
      requiresAuth: true,
      requiredRoles: ["ADMIN", "OCS_OPERATOR"],
    },
  },
  // Banner
  {
    path: "/service/banner/:page?",
    name: "Banner",
    component: () =>
      import(
        /* webpackChunkName: "service" */ "../views/service/banner/Banner.vue"
      ),
    meta: {
      title: "배너 관리",
      requiresAuth: true,
      requiredRoles: ["ADMIN", "OCS_OPERATOR"],
    },
  },
  {
    path: "/service/banner/add",
    name: "BannerAdd",
    component: () =>
      import(
        /* webpackChunkName: "service" */ "../views/service/banner/BannerAdd.vue"
      ),
    meta: {
      navTree: ["Banner"],
      title: "배너 추가",
      requiresAuth: true,
      requiredRoles: ["ADMIN", "OCS_OPERATOR"],
    },
  },
  {
    path: "/service/banner/edit/:bannerId",
    name: "BannerEdit",
    component: () =>
      import(
        /* webpackChunkName: "service" */ "../views/service/banner/BannerAdd.vue"
      ),
    meta: {
      navTree: ["Banner"],
      title: "배너 수정",
      requiresAuth: true,
      requiredRoles: ["ADMIN", "OCS_OPERATOR"],
    },
  },
  // Terms
  {
    path: "/service/policy/list/:page?",
    name: "Policy",
    component: () =>
      import(
        /* webpackChunkName: "service" */ "../views/service/policy/Policy.vue"
      ),
    meta: {
      title: "약관 관리",
      requiresAuth: true,
      requiredRoles: ["ADMIN", "OCS_OPERATOR"],
    },
  },
  {
    path: "/service/policy/add",
    name: "PolicyAdd",
    component: () =>
      import(
        /* webpackChunkName: "service" */ "../views/service/policy/PolicyAdd.vue"
      ),
    meta: {
      navTree: ["Policy"],
      title: "신규 약관 작성",
      requiresAuth: true,
      requiredRoles: ["ADMIN", "OCS_OPERATOR"],
    },
  },
  {
    path: "/service/policy/edit/:policyId",
    name: "PolicyEdit",
    component: () =>
      import(
        /* webpackChunkName: "service" */ "../views/service/policy/PolicyEdit.vue"
      ),
    meta: {
      navTree: ["Policy"],
      title: "약관 개정",
      requiresAuth: true,
      requiredRoles: ["ADMIN", "OCS_OPERATOR"],
    },
  },
  // Mail Manual
  {
    path: "/service/mail/manual",
    name: "MailManual",
    component: () =>
      import(
        /* webpackChunkName: "service" */ "../views/service/mail/manual/MailManual.vue"
      ),
    meta: {
      title: "이메일 관리",
      requiresAuth: true,
      requiredRoles: ["ADMIN", "OCS_OPERATOR"],
    },
  },
  {
    path: "/service/mail/manual/add",
    name: "MailManualAdd",
    component: () =>
      import(
        /* webpackChunkName: "service" */ "../views/service/mail/manual/MailManualAdd.vue"
      ),
    meta: {
      navTree: ["MailManual"],
      title: "이메일 작성",
      requiresAuth: true,
      requiredRoles: ["ADMIN", "OCS_OPERATOR"],
    },
  },
  {
    path: "/service/mail/manual/edit/:mailId",
    name: "MailManualEdit",
    component: () =>
      import(
        /* webpackChunkName: "service" */ "../views/service/mail/manual/MailManualAdd.vue"
      ),
    meta: {
      navTree: ["MailManual"],
      title: "이메일 수정",
      requiresAuth: true,
      requiredRoles: ["ADMIN", "OCS_OPERATOR"],
    },
  },
  // Mail Auto
  {
    path: "/service/mail/auto",
    name: "MailAuto",
    component: () =>
      import(
        /* webpackChunkName: "service" */ "../views/service/mail/auto/MailAuto.vue"
      ),
    meta: {
      title: "이메일 관리",
      requiresAuth: true,
      requiredRoles: ["ADMIN", "OCS_OPERATOR"],
    },
  },
  {
    path: "/service/mail/auto/add",
    name: "MailAutoAdd",
    component: () =>
      import(
        /* webpackChunkName: "service" */ "../views/service/mail/auto/MailAutoAdd.vue"
      ),
    meta: {
      navTree: ["MailAuto"],
      title: "이메일 작성",
      requiresAuth: true,
      requiredRoles: ["ADMIN", "OCS_OPERATOR"],
    },
  },
  // Notifications Manual
  {
    path: "/service/notifications/manual",
    name: "NotificationsManual",
    component: () =>
      import(
        /* webpackChunkName: "service" */ "../views/service/notifications/manual/NotificationsManual.vue"
      ),
    meta: {
      title: "알림 관리",
      requiresAuth: true,
      requiredRoles: ["ADMIN", "OCS_OPERATOR"],
    },
  },
  {
    path: "/service/notifications/manual/add",
    name: "NotificationsManualAdd",
    component: () =>
      import(
        /* webpackChunkName: "service" */ "../views/service/notifications/manual/NotificationsManualAdd.vue"
      ),
    meta: {
      navTree: ["NotificationsManual"],
      title: "알림 작성",
      requiresAuth: true,
      requiredRoles: ["ADMIN", "OCS_OPERATOR"],
    },
  },
  // Notifications Auto
  {
    path: "/service/notifications/auto",
    name: "NotificationsAuto",
    component: () =>
      import(
        /* webpackChunkName: "service" */ "../views/service/notifications/auto/NotificationsAuto.vue"
      ),
    meta: {
      title: "알림 관리",
      requiresAuth: true,
      requiredRoles: ["ADMIN", "OCS_OPERATOR"],
    },
  },
  {
    path: "/service/notifications/auto/add",
    name: "NotificationsAutoAdd",
    component: () =>
      import(
        /* webpackChunkName: "service" */ "../views/service/notifications/auto/NotificationsAutoAdd.vue"
      ),
    meta: {
      navTree: ["NotificationsAuto"],
      title: "알림 작성",
      requiresAuth: true,
      requiredRoles: ["ADMIN", "OCS_OPERATOR"],
    },
  },
];
