<template>
  <admin-layout> </admin-layout>
</template>

<script>
import AdminLayout from "@/layouts/AdminLayout";

export default {
  created() {
    this.$router.replace({ name: "Home" });
  },
  components: {
    "admin-layout": AdminLayout,
  },
};
</script>
